<template>
    <div class="expertPage scrollbar">
        <!-- 按钮 -->
        <div class="expert_btn">
            <div class="botton_box">
                <div class="button_list">
                    <sn-button :snButton="snButton" @handleChange="handleChange"></sn-button>
                </div>
            </div>
            
            <!-- <el-col :span="24" class="save_btn">
                <el-button v-if="isEdit" type="primary" size="small" @click="saveExpert('formdata')">保 存</el-button>
                <router-link tag="button" class="el-button el-button--danger el-button--small" :to="{ name:'expert_information_grid'}">返 回</router-link>
            </el-col> -->
        </div>
        <div class="expert_edit">
            <div class="expert_title">专业人员信息</div>
            <el-form :model="formdata.saveJson" ref="formdata" label-width="120px" class="demo-ruleForm">
                <table>
                    <tbody>
                        <tr>
                            <td rowspan="5" class="tableHead">基本信息</td>
                            <td>
                                <label for="ZJXM"><i>*</i>专家姓名</label>
                            </td>
                            <td colspan="2">
                                <el-input :disabled="!isEdit" v-model="formdata.saveJson.ZJXM" onkeydown="if(event.keyCode==32) return false"></el-input>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label for="ZJXB"><i>*</i>性别</label>
                            </td>
                            <td colspan="2">
                                <el-radio-group :disabled="!isEdit" v-model="formdata.saveJson.ZJXB">
                                    <el-radio label="0">男</el-radio>
                                    <el-radio label="1">女</el-radio>
                                </el-radio-group>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label for="MZ"><i>*</i>民族</label>
                            </td>
                            <td colspan="2">
                                <el-select v-model="formdata.saveJson.MZ" filterable>
                                    <el-option
                                        v-for="item in zjmzList"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label for="SZDW"><i>*</i>所在单位</label>
                            </td>
                            <td colspan="2">
                                <el-input :disabled="!isEdit" v-model="formdata.saveJson.SZDW" onkeydown="if(event.keyCode==32) return false"></el-input>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label for="ZWZC"><i>*</i>职称/职位</label>
                            </td>
                            <td colspan="2">
                                <el-input :disabled="!isEdit" v-model="formdata.saveJson.ZWZC" onkeydown="if(event.keyCode==32) return false"></el-input>
                            </td>
                        </tr>
                        <tr>
                            <td rowspan="3" class="tableHead">联系方式</td>
                            <td>
                                <label for="LXDH"><i>*</i>电话</label>
                            </td>
                            <td colspan="2">
                                <el-input v-if="editDH==0" :disabled="!isEdit||(!isAdd&&!editDH)" v-model="formdata.saveJson.LXDH" onkeyup="this.value=this.value.replace(/[^\d.]/g,'');" onkeydown="if(event.keyCode==32) return false"></el-input>
                                <el-input v-else :disabled="editDH==2" v-model="nowDH" onkeyup="this.value=this.value.replace(/[^\d.]/g,'');" onkeydown="if(event.keyCode==32) return false"></el-input>
                                <i v-if="!isAdd&&isEdit&&editDH!=1" class="editicon el-icon-edit" @click="editDH=1;"></i>
                                <i v-if="editDH == 1" class="editicon editicon1 el-icon-circle-check" title="确认修改" @click="editDH=2;"></i>
                                <i v-if="editDH == 1" class="editicon el-icon-circle-close" title="取消修改" @click="editDH=0;"></i>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label for="YX">邮箱</label>
                            </td>
                            <td colspan="2">
                                <el-input v-if="editYX==0" :disabled="!isEdit||(!isAdd&&!editYX)" v-model="formdata.saveJson.YX" onkeydown="if(event.keyCode==32) return false" @blur="yxVerify"></el-input>
                                <el-input v-else :disabled="editYX==2" v-model="nowYX" onkeydown="if(event.keyCode==32) return false"></el-input>
                                <i v-if="!isAdd&&isEdit&&editYX!=1" class="editicon el-icon-edit" @click="editYX=1;"></i>
                                <i v-if="editYX == 1" class="editicon editicon1 el-icon-circle-check" title="确认修改" @click="editYX=2;"></i>
                                <i v-if="editYX == 1" class="editicon el-icon-circle-close" title="取消修改" @click="editYX=0;"></i>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label for="DZ">地址</label>
                            </td>
                            <td colspan="2">
                                <el-input :disabled="!isEdit" v-model="formdata.saveJson.DZ" onkeydown="if(event.keyCode==32) return false"></el-input>
                            </td>
                        </tr>
                        <!-- <tr v-for="(item,index) in YJLXAndYJFXList" :key="item.CODE" v-model="YJLXAndYJFXList"> -->
                        <tr v-for="(item,index) in YJLXAndYJFXList" :key="item.CODE">
                            <td class="tableHead" v-if="index==0" :rowspan="YJLXAndYJFXList.length">
                                <label for="YJFX"><i>*</i>研究方向</label>
                            </td>
                            <!-- <td :label="item.CODE" v-model="yjfxlxList">{{item.NAME}}</td> -->
                            <td :label="item.CODE">{{item.NAME}}</td>
                            <td colspan="2">
                                <!-- <el-input v-model="formdata.saveJson.QTYJFX" :disabled="!isEdit" v-if="item.CODE==8" :label="item.CODE" placeholder="填写具体研究方向"></el-input>
                                <el-checkbox :disabled="!isEdit" v-else v-model="yjfxList" v-for="z in item.CHILDREN" :key="z.CODE" :label="z.CODE" @change="changeYJFX(z)">{{z.NAME}}</el-checkbox> -->
                                <el-checkbox :disabled="!isEdit" v-model="yjfxList" v-for="z in item.CHILDREN" :key="z.CODE" :label="z.CODE" @change="changeYJFX(z)">{{z.NAME}}</el-checkbox>
                            </td>
                        </tr>
                        <tr>
                            <td rowspan="2" class="tableHead">研究成果</td>
                            <td>
                                <label for="XYYJCG">现有研究成果</label>
                            </td>
                            <td colspan="2">
                                <el-input v-if="!isEdit" :disabled="!isEdit" v-model="formdata.saveJson.XYYJCG"></el-input>
                                <!-- <textarea v-else v-model="formdata.saveJson.XYYJCG" name="" id="xyyjcg-id" placeholder="（文字描述）"></textarea> -->
                                <el-input type="textarea" :rows="6" v-else v-model="formdata.saveJson.XYYJCG" onkeydown="if(event.keyCode==32) return false"></el-input>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label for="ZZJXDXGYJ">正在进行的相关研究/课题</label>
                            </td>
                            <td colspan="2">
                                <el-input v-if="!isEdit" :disabled="!isEdit" v-model="formdata.saveJson.ZZJXDXGYJ"></el-input>
                                <!-- <textarea v-else v-model="formdata.saveJson.ZZJXDXGYJ" :disabled="!isEdit" name="" id="xyyjcg-id" placeholder="（文字描述）"></textarea> -->
                                <el-input type="textarea" :rows="6" v-else v-model="formdata.saveJson.ZZJXDXGYJ" :disabled="!isEdit" onkeydown="if(event.keyCode==32) return false"></el-input>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </el-form>
        </div>
    </div>
</template>

<script>
import snButton from "./model/edit-button.js";
import infoMixin from "../info_mixin.js";
import { mapActions } from "vuex";
export default {
    name: "expert_information_form",
    components: {},
    mixins: [infoMixin],
    data() {
        return {
            snButton: snButton,
            formdata: {
                saveJson: {
                    ZJXM: "", // 专家姓名
                    ZJXB: "", // 性别
                    MZ: "", // 民族
                    SZDW: "", // 所在单位
                    ZWZC: "", // 职称/职位
                    LXDH: "", // 电话
                    YX: "", // 邮箱
                    DZ: "", // 地址
                    YJFX: "", // 研究方向
                    YJFXLX: "", // 研究方向类型
                    XYYJCG: "", // 现有研究成果
                    ZZJXDXGYJ: "", //正在进行的相关研究
                    
                    // QTYJFX: "", // 其他研究方向
                },
                heritageId: "",
                userName: "",
                userId: ""
            },
            zjmzList:[],
            editDH: 0,
            editYX: 0,
            nowDH: "",
            nowYX: "",
            yjfxList: [],
            yjfxlxList: [],
            YJLXAndYJFXList: [], // 研究类型和研究方向列表
            yjlxAndYjfxList:[],
            dhIsTrue: true, // 电话
            yxIsTrue: true, // 邮箱
            mzList: [],
        };
    },
    mounted() {
        if (localStorage.expertID) {
            // this.isEdit = localStorage.expertID
        }
        this.getYJFXList()
        this.setZjmzList();

        // eslint-disable-next-line no-undef
        $(document).ready(function(){
            let tmp = document.getElementsByClassName("el-textarea__inner");
            if(tmp && tmp.length > 0 && tmp[0].className){
                tmp.forEach(item => {
                    item.className = "el-textarea__inner scrollbar";
                })
            }
        });
    },
    methods: {
        ...mapActions([
            "SaveExpertInfo", //保存专家信息服务
            "GetExpertInfoDetails", //获取专家详情服务
            'GetResearchDirection', //获取专家的研究方向树结构
        ]),
        async getFromData() {
            this.yjfxList = [];
            this.yjfxlxList = [];
            this.fjname = '';
            let result = await this.GetExpertInfoDetails({
                id: this.id
            });
            if (result && result.length > 0) {
                Object.assign(this.formdata.saveJson, result[0]);
                if (this.formdata.saveJson.YJFX && this.formdata.saveJson.YJFX != "") {
                    this.yjfxList = this.formdata.saveJson.YJFX.split(",")
                }
                if (this.formdata.saveJson.YJFXLX && this.formdata.saveJson.YJFXLX != "") {
                    this.yjfxlxList = this.formdata.saveJson.YJFXLX.split(",")
                }
                if (this.formdata.saveJson.WJMC != null) {
                    this.fjname = this.formdata.saveJson.WJMC
                }
            }
        },

        yxVerify() {
            // 邮箱
            let _this = this
            let YX = _this.editYX == 2 ? _this.nowYX : _this.formdata.saveJson.YX
            let reg = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z-]+)*\.[a-zA-Z]{2,6}$/
            if (YX) {
                if (reg.test(YX)) {
                    _this.yxIsTrue = true
                } else {
                    _this.showMsg("邮箱格式错误，请重新输入", "error")
                    _this.yxIsTrue = false
                    return false
                }
            }
        },
        changeYJFX(z) {
            // 改变研究方向
            let _this = this
            let selectYJLX = []
            if (_this.yjfxlxList.indexOf(z.PID) == -1) {
                _this.yjfxlxList.push(z.PID)
            }
            if (_this.yjfxList.length == 0) {
                _this.yjfxlxList = []
            }
            // _this.getYJFXList()
            _this.yjfxList.forEach((item) => {
                _this.yjlxAndYjfxList.forEach((items) => {
                    for (let i = 0; i < items.CHILDREN.length; i++) {
                        if (items.CHILDREN[i].CODE == item && selectYJLX.indexOf(items.CHILDREN[i].PID) === -1) {
                            selectYJLX.push(items.CHILDREN[i].PID)
                        }
                    }
                })
            })
            _this.yjfxlxList = selectYJLX
        },
        // 获取民族列表
        setZjmzList() {
            this.zjmzList = [];
            let result = [
                {value: '1' , label:' 汉族'},
                {value: '2' , label:' 蒙古族'},
                {value: '3' , label:' 回族'},
                {value: '4' , label:' 藏族'},
                {value: '5' , label:' 维吾尔族'},
                {value: '6' , label:' 苗族'},
                {value: '7' , label:' 彝族'},
                {value: '8' , label:' 壮族'},
                {value: '9' , label:' 布依族'},
                {value: '10', label:' 朝鲜族'},
                {value: '11', label:' 满族'},
                {value: '12', label:' 侗族'},
                {value: '13', label:' 瑶族'},
                {value: '14', label:' 白族'},
                {value: '15', label:' 土家族'},
                {value: '16', label:' 哈尼族'},
                {value: '17', label:' 哈萨克族'},
                {value: '18', label:' 傣族'},
                {value: '19', label:' 黎族'},
                {value: '20', label:' 傈僳族'},
                {value: '21', label:' 佤族'},
                {value: '22', label:' 畲族'},
                {value: '23', label:' 高山族'},
                {value: '24', label:' 拉祜族'},
                {value: '25', label:' 水族'},
                {value: '26', label:' 东乡族'},
                {value: '27', label:' 纳西族'},
                {value: '28', label:' 景颇族'},
                {value: '29', label:' 柯尔克孜族'},
                {value: '30', label:' 土族'},
                {value: '31', label:' 达翰尔族'},
                {value: '32', label:' 么佬族'},
                {value: '33', label:' 羌族'},
                {value: '34', label:' 布朗族'},
                {value: '35', label:' 撒拉族'},
                {value: '36', label:' 毛南族'},
                {value: '37', label:' 仡佬族'},
                {value: '38', label:' 锡伯族'},
                {value: '39', label:' 阿昌族'},
                {value: '40', label:' 普米族'},
                {value: '41', label:' 塔吉克族'},
                {value: '42', label:' 怒族'},
                {value: '43', label:' 乌孜别克族'},
                {value: '44', label:' 俄罗斯族'},
                {value: '45', label:' 鄂温克族'},
                {value: '46', label:' 德昂族'},
                {value: '47', label:' 保安族'},
                {value: '48', label:' 裕固族'},
                {value: '49', label:' 京族'},
                {value: '50', label:' 塔塔尔族'},
                {value: '51', label:' 独龙族'},
                {value: '52', label:' 鄂伦春族'},
                {value: '53', label:' 赫哲族'},
                {value: '54', label:' 门巴族'},
                {value: '55', label:' 珞巴族'},
                {value: '56', label:' 基诺族'}
            ];
            if(result && result.length>0){
                this.zjmzList = result;
            }
        },
        // 获取研究方向列表
        async getYJFXList() {
            this.YJLXAndYJFXList = [];
            let da = await this.GetResearchDirection();
            if (da && da.length > 0) {
                this.YJLXAndYJFXList = da
            }
            this.yjlxAndYjfxList = da || [];
        },
        async saveNosubmit(formName) {
            formName = 'formdata';
            //保存专业人员信息
            let _this = this;
            // 判断邮箱的格式是否正确
            if(!_this.isAdd){
                if (_this.editYX == 2) {
                    if (_this.editYX == 2 && _this.yxIsTrue) _this.formdata.saveJson.YX = _this.nowYX;
                } else {
                    delete _this.formdata.saveJson.YX;
                }
            }
            _this.yxVerify();
            
            if (!_this.yxIsTrue) return false;
            _this.$refs[formName].validate(valid => {
                if (
                    valid &&
                    _this.formdata.saveJson.ZJXM != "" &&
                    _this.formdata.saveJson.ZJXB != "" &&
                    _this.formdata.saveJson.MZ != "" &&
                    _this.formdata.saveJson.SZDW != "" &&
                    _this.formdata.saveJson.ZWZC != "" &&
                    ((_this.nowDH != "" && _this.editDH == 2) || (_this.formdata.saveJson.LXDH != "" && _this.editDH != 2)) &&
                    _this.yxIsTrue == true
                ) {
                    if (!_this.isAdd) {
                        // 只有编辑的时候才判断是否保存标*的这些字段
                        if (_this.editDH == 2) {
                            _this.formdata.saveJson.LXDH = _this.nowDH
                        } else {
                            delete _this.formdata.saveJson.LXDH
                        }
                    }

                    // if (_this.formdata.saveJson.QTYJFX) {
                    //     _this.yjfxlxList.push(8);
                    // } else {
                    //     if (_this.yjfxlxList.indexOf(8) > -1) {
                    //         _this.yjfxlxList = _this.yjfxlxList.splice(_this.yjfxlxList.indexOf(8), 1);
                    //     }
                    // }
                    _this.formdata.saveJson.YJFX = _this.yjfxList.toString();
                    _this.formdata.saveJson.YJFXLX = _this.yjfxlxList.toString();
                    if(_this.formdata.saveJson.YJFX == "" || _this.formdata.saveJson.YJFXLX == "") {
                        _this.showMsg("请选择研究方向", "error")
                        return;
                    }
                    _this.saveTableData();
                    
                } else if (_this.formdata.saveJson.ZJXM == "") {
                    _this.showMsg("请输入专家姓名", "error")
                } else if (_this.formdata.saveJson.ZJXB == "") {
                    _this.showMsg("请选择性别", "error")
                } else if (_this.formdata.saveJson.MZ == "") {
                    _this.showMsg("请选择民族", "error")
                } else if (_this.formdata.saveJson.SZDW == "") {
                    _this.showMsg("请输入所在单位", "error")
                } else if (_this.formdata.saveJson.ZWZC == "") {
                    _this.showMsg("请输入职称/职位", "error")
                } else if ((_this.nowDH == "" && _this.editDH == 2) ||
                    (_this.formdata.saveJson.LXDH == "" && _this.editDH != 2)) {
                    _this.showMsg("请输入电话", "error")
                } else {
                    return false
                }
            })
        },
        async saveTableData(){
            let _this = this;
            // let data = _this.formdata.saveJson;
            // let da = await _this.SaveExpertInfo(data);
            let da = await _this.SaveExpertInfo({
                jsonstr: _this.formdata.saveJson
            });
            if (da.IsSuccess == true) {
                _this.showMsg("保存成功！", "success")
                _this.$router.push({
                    name: "expert_information_grid"
                })
            } else {
                _this.showMsg("保存失败！", "error")
            }
        },
        showMsg(msg, state) {
            //信息提示
            this.$message({
                showClose: true,
                message: msg,
                type: state,
                duration: 2000
            })
        }
    }
};
</script>
<style scoped>
.expertPage {
    position: absolute;
    top: 72px;
    left: 120px;
    width: calc(100% - 140px);
    height: calc(100% - 72px);
    overflow-y: auto;
    user-select: none;
}
.expert_btn {
    position: absolute;
    top: 35px;
    right: 50px;
}

.expert_edit {
    width: 50%;
    min-width: 960px;
    margin: 0 auto;
    padding-top: 40px;
}

.expert_edit .el-input__inner {
    border: 1px solid transparent !important;
}

.expert_edit div.el-form-item {
    margin: 0;
}

div.el-form-item .el-form-item__label {
    padding: 0;
}

.expert_title {
    height: 45px;
    line-height: 45px;
    font-size: 16px;
    color: #23468c;
    background-color: #d6dfcc;
    font-weight: 700;
    letter-spacing: 3px;
    text-align: center;
}

.demo-ruleForm table {
    width: 100%;
}

table td {
    width: 170px;
    border-right: 1px solid #ddd;
    height: 30px;
    font-size: 14px;
    padding: 0;
    text-align: center;
}

.tableHead {
    width: 200px;
    background-color: #f7f9f4;
    color: #23468c;
    font-weight: bold;
    box-sizing: border-box;
    border-right: 3px solid #fff;
    border-bottom: 5px solid #fff;
    text-align: center;
}

tr:nth-child(odd) {
    background: #f7f9f4 !important;
}

#xyyjcg-id {
    background-color: rgba(0,0,0,0);
}

label i {
    color: red;
    margin-right: 5px;
    font-style: normal;
    font-weight: 700;
}

textarea {
    width: 100%;
    height: 36px;
    font-size: 14px;
}

.el-checkbox {
    text-align: left;
    line-height: 45px;
    float: left;
    margin: 0 10px !important;
    width: 29%;
}

.expert_edit .el-date-editor.el-input,
.expert_edit .el-select {
    width: 100%;
}

.expert_edit .el-form-item {
    margin-bottom: 15px;
}

.el-radio-group {
    width: 100%;
    padding: 10px 0;
}

.el-radio {
    margin: 0 8px;
    float: left;
}

.demo-ruleForm .first_span {
    right: 105px !important;
}

.save_btn {
    text-align: right;
    margin-top: 5px;
    margin-bottom: 15px;
}

td {
    position: relative;
}

.editicon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 14px;
}

.editicon1 {
    right: 30px;
}
</style>